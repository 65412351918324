import {
  Route,
  BrowserRouter as Router,
  Switch,
  BrowserRouter,
} from "react-router-dom";
import Home from "pages/Home";
import Entities from "pages/Entities";
import UiElement from "pages/ui";

export default function DefaultRouter() {
  return (
    <BrowserRouter>
      <Router>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/entities" component={Entities} />
          <Route exact path="/ui" component={UiElement} />
          <Route path="/" component={Home} />
        </Switch>
      </Router>
    </BrowserRouter>
  );
}
