import joinClasses from "helpers/joinClasses";

export function Notification({ children, className, ...props }) {
  return (
    <span
      className={joinClasses(
        className,
        "text-[10px] avenir-black-font px-1 py-0.5 rounded-full"
      )}
      {...props}
    >
      {children}
    </span>
  );
}
