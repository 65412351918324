import ReactCodeInput from "react-code-input";

export function InputCode({ length = 6, setCode = () => {}, ...props }) {
  return (
    <div className="flex gap-3">
      <ReactCodeInput
        type="number"
        fields={length}
        onChange={setCode}
        className="text-black !flex gap-2 cera-medium-font"
        inputStyle={{
          width: "40px",
          borderRadius: "0px",
          MozAppearance: "textfield",
          fontSize: "16px",
          height: "35px",
          color: "#0027ff",
          border: "none",
          borderBottom: "1px solid #0027ff",
        }}
        {...props}
      />
    </div>
  );
}
