import { axiosRequest } from "helpers/axios";

export async function fetchLogin(user) {
  const response = await axiosRequest({
    method: "POST",
    url: "/authentication/login",
    data: {
      ...user,
    },
  });

  if (response.status === "done") {
    return { ...response, stay_connected: user.stay_connected };
  }

  return response;
}

export async function fetchRegisterEmail(email) {
  return await axiosRequest({
    method: "POST",
    url: "/authentication/register-email",
    data: {
      email: email,
    },
  });
}

export async function fetchRegister(user) {
  return await axiosRequest({
    method: "POST",
    url: "/authentication/register",
    data: {
      ...user,
    },
  });
}

export async function fetchVerifyEmail(code, email) {
  return await axiosRequest({
    method: "POST",
    url: "/authentication/verify-email",
    data: {
      email: email,
      code: Number(code),
    },
  });
}

export async function fetchRegisterIp(code, email) {
  return await axiosRequest({
    method: "POST",
    url: "/authentication/register-ip",
    data: {
      email: email,
      code: Number(code),
    },
  });
}

export async function fetchCheckToken(token) {
  return await axiosRequest({
    method: "GET",
    url: "/authentication/auth",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function fetchUserInfo(id, token) {
  return await axiosRequest({
    method: "GET",
    url: `/users/${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}
