import joinClasses from "helpers/joinClasses";

function Button({ children, className = "", ...props }) {
  return (
    <button className={joinClasses(className, "cursor-pointer")} {...props}>
      {children}
    </button>
  );
}

export default Button;
