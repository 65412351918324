import joinClasses from "helpers/joinClasses";
import { Input } from "./Inputs";

export function InputFile({ className, title, preview = "", ...props }) {
  return (
    <div
      className="w-[150px] h-[150px] relative bg-beige-lightest bg-opacity-50 flex justify-center items-center bg-center bg-contain bg-no-repeat"
      style={{ backgroundImage: `url("${preview}")` }}
    >
      <i className="icon-picture text-blue-dark text-4xl" />
      <Input
        {...props}
        type="file"
        className={joinClasses(
          className,
          "w-[150px] h-[150px] absolute opacity-0"
        )}
      />
      {title}
    </div>
  );
}
