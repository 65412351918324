import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchCheckToken, fetchLogin, fetchUserInfo } from "api/auth";
import Cookies from "universal-cookie";
const cookies = new Cookies();

const initialState = {
  token: cookies.get("::token") || null,
  error: "",
  status: "done",
  user: {
    id: "",
    email: "",
    firstname: "",
    lastname: "",
    role: "",
    status: "",
  },
};

export const getUserInfo = createAsyncThunk("user/login", async (user) => {
  const response = await fetchLogin(user);
  return { response: response, oldInput: user };
});

export const checkUserToken = createAsyncThunk(
  "user/checkToken",
  async (arg, { getState }) => {
    const state = getState();

    const response = await fetchCheckToken(state.user.token);
    if (response.response.user) {
      if (!state.user.user.id.length) {
        const { id } = response.response.user;
        const userData = await fetchUserInfo(id, state.user.token);
        const { userFind } = userData.response.data;
        return userFind;
      }
      return;
    }
    return response.response;
  }
);

export const setUserInfo = createSlice({
  name: "user",
  initialState,
  reducers: {
    removeUserInfo: (state) => {
      state.token = null;
      state.error = "";
      state.status = "done";
      state.user = {
        id: "",
        email: "",
        firstname: "",
        lastname: "",
        role: "",
        status: "",
      };
      cookies.remove("::token");
    },
    addUserInfo: (state, action) => {
      state.error = "";
      state.status = "done";
      state.user = action.payload.user;
      if (action.payload.token) {
        state.token = action.payload.token;
        cookies.set("::token", action.payload.token, {
          path: "/",
        });
      }
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(checkUserToken.pending, (state) => {
        state.error = "";
        state.status = "loading";
      })
      .addCase(checkUserToken.fulfilled, (state, action) => {
        if (action.payload && action.payload.status === "error") {
          state.error = action.payload.response;
          state.status = "done";
        } else if (action.payload) {
          state.error = "";
          state.status = "done";
          state.user = {
            id: action.payload.id,
            email: action.payload.email,
            firstname: action.payload.firstname,
            lastname: action.payload.lastname,
            role: action.payload.Role,
            status: action.payload.status,
          };
        }
      });
    builder
      .addCase(getUserInfo.pending, (state) => {
        state.error = "";
        state.status = "loading";
      })
      .addCase(getUserInfo.fulfilled, (state, action) => {
        if (
          action.payload.response.status &&
          action.payload.response.status === "error"
        ) {
          state.error = action.payload.response.response;
          state.status = "done";
          if (action.payload.response.code === "A0008") {
            cookies.remove("::token");
            state.error = action.payload.response.code;
            state.user = action.payload.oldInput;
          }
        } else {
          state.error = "";
          state.status = "done";
          state.token = action.payload.response.response.token;
          state.user = action.payload.response.response.user;
          cookies.set("::token", action.payload.response.response.token, {
            path: "/",
          });
        }
      });
  },
});

export const { removeUserInfo, addUserInfo } = setUserInfo.actions;

export const userInfo = (state) => state.user;

export default setUserInfo.reducer;
