import { axiosRequest } from "helpers/axios";
import getLocalToken from "helpers/getLocalToken";

export async function fetchEntities() {
  const response = await axiosRequest({
    method: "GET",
    url: "/entities",
    headers: {
      Authorization: getLocalToken(),
    },
  });

  if (response.status === "done") {
    return { ...response };
  }

  return response;
}

export async function fetchCreateEntitie(entitie) {
  const formData = new FormData();
  console.log("entitie", entitie);
  formData.append("title", entitie.title);
  if (entitie.avatar) {
    formData.append("avatar", entitie.avatar);
  }
  const response = await axiosRequest({
    method: "POST",
    url: "/entities",
    headers: {
      Authorization: getLocalToken(),
    },
    data: formData,
  });
  if (response.status === "done") {
    const { entity } = response.response;
    return { ...response, entitie: entity.payload };
  }

  return response;
}

export async function fetchEditEntitie(entitie) {
  const formData = new FormData();
  formData.append("id", entitie.id);
  formData.append("title", entitie.title);
  console.log("entitie", entitie);
  if (entitie.avatar) {
    formData.append("avatar", entitie.avatar);
  }
  const response = await axiosRequest({
    method: "PUT",
    url: `/entities`,
    headers: {
      Authorization: getLocalToken(),
    },
    data: formData,
  });
  if (response.status === "done") {
    const { data } = response.response;
    return { ...response, entitie: data.entity };
  }

  return response;
}

export async function fetchDeleteEntitie(id) {
  const response = await axiosRequest({
    method: "DELETE",
    url: `/entities/${id}`,
    headers: {
      Authorization: getLocalToken(),
    },
  });

  return response;
}
