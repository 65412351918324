import {
  Route,
  BrowserRouter as Router,
  Switch,
  BrowserRouter,
} from "react-router-dom";
import Login from "../pages/Auth/login";
import Register from "../pages/Auth/register";

export default function AuthenticationRouter() {
  return (
    <BrowserRouter>
      <Router>
        <Switch>
          <Route exact path="/auth/register" component={Register} />
          <Route path="/" component={Login} />
        </Switch>
      </Router>
    </BrowserRouter>
  );
}
