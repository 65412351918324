import joinClasses from "helpers/joinClasses";
import Button from "./Button";

export function IconButton({ icon, className, ...props }) {
  return (
    <Button
      className={joinClasses(
        "border w-[30px] h-[30px] flex justify-center items-center rounded-full text-sm",
        className
      )}
      {...props}
    >
      <i className={`icon-${icon}`} />
    </Button>
  );
}
