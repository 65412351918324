import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Logo, NavLink } from "../../atoms";
import { ProfilLink } from "../../molecules";

export function NavbarBoard() {
  const { pathname } = useLocation();
  const [isMobileOpen, setIsMobileOpen] = useState(false);
  const navlist = [
    {
      name: "entités / filiales",
      path: "/entities",
    },
    {
      name: "plans de successions",
      path: "/successions",
    },
    {
      name: "talent management",
      path: "/",
    },
    {
      name: "statistiques",
      path: "/stats",
    },
  ];

  return (
    <>
      {/*  burger nav  */}
      <div
        onClick={() => setIsMobileOpen(!isMobileOpen)}
        className={`z-20 cursor-pointer lg:hidden fixed right-8  ${
          isMobileOpen ? "top-5" : "top-4"
        }`}
      >
        <span
          className={`block  h-0.5 w-5 my-1 transition-all transform ${
            isMobileOpen
              ? "absolute rotate-45 translate-y-0.5 bg-black "
              : "bg-white"
          }`}
        ></span>
        <span
          className={`block bg-white h-0.5 w-5 my-1 transition-all ${
            isMobileOpen && "opacity-0"
          }`}
        ></span>
        <span
          className={`block h-0.5 w-5 my-1 transition-all transform ${
            isMobileOpen
              ? "absolute -rotate-45 -translate-y-2 bg-black "
              : "bg-white"
          }`}
        ></span>
      </div>
      <div className="bg-blue-dark w-full flex fixed top-0 px-8 h-14 justify-between items-center lg:hidden">
        <Link className="block lg:hidden" to="/">
          <Logo width="200" />
        </Link>
      </div>
      {/*  END burger nav  */}

      <nav
        className={` transform transition-all ${
          isMobileOpen ? " translate-x-0" : "translate-x-full"
        } lg:translate-x-0 lg:transition-none bg-white h-full max-h-screen fixed text-black lg:text-white flex flex-col justify-center w-full px-20 2xl:px-0 py-8 lg:static lg:block lg:h-auto lg:flex-row lg:bg-blue-dark z-10 `}
      >
        <div className="2xl:m-auto flex flex-col h-full items-center w-full max-w-screen-2xl justify-between lg:flex-row lg:h-auto ">
          <Link className="hidden lg:block" to="/">
            <Logo width="260" />
          </Link>
          <ul className="flex flex-col items-center gap-10  mt-14 lg:mt-0 lg:flex-row">
            {navlist.map((item, index) => (
              <NavLink
                key={index}
                url={item.path}
                active={item.path === pathname}
              >
                {item.name}
              </NavLink>
            ))}
          </ul>
          <ProfilLink />
        </div>
      </nav>
    </>
  );
}
