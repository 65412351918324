import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchCheckToken, fetchUserInfo } from "api/auth";
import {
  fetchEntities,
  fetchCreateEntitie,
  fetchEditEntitie,
} from "api/entities";

const initialState = {
  error: "",
  status: "done",
  data: [],
};

export const getEntities = createAsyncThunk("entities/getAll", async () => {
  const response = await fetchEntities();
  return response;
});

export const createEntities = createAsyncThunk(
  "entities/create",
  async (entitie) => {
    const response = await fetchCreateEntitie(entitie);
    return response;
  }
);

export const editEntity = createAsyncThunk("entities/edit", async (entitie) => {
  const response = await fetchEditEntitie(entitie);
  return response;
});

export const checkUserToken = createAsyncThunk(
  "user/checkToken",
  async (arg, { getState }) => {
    const state = getState();

    const response = await fetchCheckToken(state.user.token);
    if (response.response.user) {
      if (!state.user.user.id.length) {
        const { id } = response.response.user;
        const userData = await fetchUserInfo(id, state.user.token);
        const { userFind } = userData.response.data;
        return userFind;
      }
      return;
    }
    return response.response;
  }
);

// fonction qui cherche l'ndex d'un array celon on id

export const setEntities = createSlice({
  name: "user",
  initialState,
  reducers: {
    removeEntity: (state, action) => {
      const index = state.data.findIndex((item) => item.id === action.payload);
      state.data.splice(index, 1);
    },
    addEntity: (state, action) => {
      state.error = "";
      state.status = "done";
      state.data.push(action.payload.entitie);
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(editEntity.pending, (state) => {
        state.error = "";
        state.status = "loading";
      })
      .addCase(editEntity.fulfilled, (state, action) => {
        if (action.payload && action.payload.status === "error") {
          state.error = action.payload.response;
          state.status = "done";
          return;
        }

        state.error = "";
        state.status = "done";

        const entityIndex = state.data.findIndex(
          (item) => item.id === action.payload.entitie.id
        );
        state.data[entityIndex] = action.payload.entitie;
      });
    builder
      .addCase(createEntities.pending, (state) => {
        state.error = "";
        state.status = "loading";
      })
      .addCase(createEntities.fulfilled, (state, action) => {
        if (action.payload && action.payload.status === "error") {
          state.error = action.payload.response;
          state.status = "done";
          return;
        }

        state.error = "";
        state.status = "done";
        state.data.push(action.payload.entitie);
      });
    builder
      .addCase(getEntities.pending, (state) => {
        state.error = "";
        state.status = "loading";
      })
      .addCase(getEntities.fulfilled, (state, action) => {
        if (action.payload.status && action.payload.status === "error") {
          state.error = action.payload.response;
          state.status = "done";
          return;
        }

        const { entities } = action.payload.response.data;
        state.error = "";
        state.status = "done";
        state.data = entities;
      });
  },
});

export const { removeEntity, addEntity } = setEntities.actions;

export const entities = (state) => state.entities;

export default setEntities.reducer;
