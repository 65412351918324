import RoundedButton from "components/atoms/Buttons/RoundedButton";
import { Logo } from "../atoms";

export function AuthTemplate({ children }) {
  return (
    <div className="flex flex-col lg:flex-row gap-16 px-10 lg:px-0 w-screen overflow-hidden">
      <Logo
        className="block lg:hidden fixed top-5"
        color="#0027ff"
        width="200px"
      />
      <div className="hidden lg:block w-7/12 xl:w-3/5 h-screen bg-blue-dark py-12 px-14 max-w-4xl xl:max-w-6xl">
        <Logo className="hidden pl-20 lg:block" />
        <div className="xl:ml-36 flex flex-col justify-center items-start h-full">
          <img src="/assets/img/login_illustration.png" alt="" />
          <div className="cera-font text-white ml-20 mt-5">
            <h1 className="cera-light-font text-3xl xl:text-4xl ">
              <span className="cera-black-font text-4xl xl:text-5xl block">
                Talent management
              </span>
              & Plans de successions
            </h1>
            <p className="my-5">
              Suivez vos talents et aidez-les à se développer au sein de votre
              entreprise. Scénarisez vos plans de succession.
            </p>
            <RoundedButton link="/">EN SAVOIR PLUS</RoundedButton>
          </div>
        </div>
      </div>
      <div className="flex flex-col justify-center h-screen w-full lg:w-4/12 lg:max-w-xs 2xl:max-w-sm">
        {children}
      </div>
    </div>
  );
}
