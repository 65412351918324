import joinClasses from "helpers/joinClasses";
import Button from "./Button";

function RoundedButton({ children, className = "", isFill = false, ...props }) {
  return (
    <Button
      className={joinClasses(
        "text-xs transition inline-block text-center rounded-full px-10 py-2.5 border border-white cursor-pointer tracking-wider",
        isFill
          ? "bg-blue-dark text-white avenir-black-font"
          : "bg-transparent  text-white avenir-black-font hover:bg-white hover:text-blue-dark",
        className
      )}
      {...props}
    >
      {children}
    </Button>
  );
}

export default RoundedButton;
