import { Link } from "react-router-dom";

export function Title(props) {
  return (
    <h1 {...props} className="avenir-medium-font font-xl">
      {props.children}
    </h1>
  );
}

export function NavLink({ children, onClick, url, active }) {
  return (
    <li>
      <Link
        to={url || "/"}
        onClick={onClick}
        className={`avenir-black-font text-xs uppercase hover:border-b-8 hover:pb-1 hover:border-beige-lightest hover:pt-10 ${
          active ? "border-b-8 pb-1 border-beige-lightest" : ""
        }`}
      >
        {children}
      </Link>
    </li>
  );
}
