import { NavbarBoard } from "../organisms";

export function ConnectedUserTemplate({ children }) {
  return (
    <div className="default-template min-h-screen bg-blue-lightest">
      <NavbarBoard />
      <div>{children}</div>
    </div>
  );
}
