import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./models/userInfo";
import entitiesReducer from "./models/entities";

export const store = configureStore({
  reducer: {
    user: userReducer,
    entities: entitiesReducer,
  },
});
