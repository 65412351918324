import joinClasses from "helpers/joinClasses";
import Button from "./Button";

export function SquareButton({
  children,
  className = "",
  isFill = false,
  ...props
}) {
  return (
    <Button
      className={joinClasses(
        className,
        "text-base text-center px-10 py-3 border-2 border-blue-dark",
        isFill
          ? "bg-transparent text-blue-dark cera-bold-font hover:bg-blue-dark hover:text-white"
          : "bg-blue-dark text-white cera-font"
      )}
      {...props}
    >
      {children}
    </Button>
  );
}
