import joinClasses from "helpers/joinClasses";

function Card({ children, className = "", ...props }) {
  return (
    <div className={joinClasses(className, "bg-white p-5")} {...props}>
      {children}
    </div>
  );
}

export default Card;
