import { SquareButton } from "components/atoms";

export default function UiElement() {
  return (
    <div className="grid gap-5 p-10">
      <SquareButton onClick={() => alert("j'ai dis: ptn zubi")}>
        S’inscrire
      </SquareButton>
      <SquareButton isFill={true} link={"/auth/register"}>
        S’inscrire
      </SquareButton>
    </div>
  );
}
