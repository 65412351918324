export default function Toolbar({ rightSide = false, children, ...props }) {
  return (
    <div
      className={`bg-beige-lightest avenir-light-font w-full ${
        rightSide && "flex justify-end"
      }`}
      {...props}
    >
      <div
        className={`flex  justify-between items-center  py-3 ${
          rightSide ? "max-w-screen-lg  w-full" : "max-w-screen-xl m-auto"
        }`}
      >
        {children}
      </div>
    </div>
  );
}
