import { fetchCreateEntitie } from "api/entities";
import { ErrorForm, LineInputText } from "components/atoms";
import RoundedButton from "components/atoms/Buttons/RoundedButton";
import { InputFile } from "components/atoms/Inputs/InputFile";
import CropImage from "components/molecules/CropImage";
import Form from "components/molecules/Form";
import Modal from "components/molecules/Modal";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { addEntity, editEntity } from "store/models/entities";

export default function ModalFormEntity({
  isModalOpen,
  setIsModalOpen,
  entity = null,
}) {
  const dispatch = useDispatch();
  const [error, setError] = useState("");
  const [avatar, setAvatar] = useState(null);
  const [loading, setLoading] = useState(false);
  const [avatarBlob, setAvatarBlob] = useState(null);
  const [entityForm, setEntityForm] = useState({
    avatar: "",
    title: "",
  });

  useEffect(() => {
    if (entity) {
      setEntityForm(entity);
      setAvatarBlob(entity.avatar);
    }
  }, [entity]);

  async function sendCreateEntity(e) {
    e.preventDefault();
    setLoading(true);

    const entity = await fetchCreateEntitie({
      ...entityForm,
      avatar: avatar,
    });
    if (entity.status === "done") {
      dispatch(addEntity(entity));
      setIsModalOpen(false);
    }
    if (entity.status === "error") {
      setError(entity.response);
    }
    setLoading(false);
  }

  async function sendEditEntity(e) {
    e.preventDefault();
    setLoading(true);
    const response = await dispatch(
      editEntity({
        ...entityForm,
        avatar: avatar,
      })
    );
    if (response.payload.status === "done") {
      setLoading(false);
      setIsModalOpen(false);
    }
  }

  useEffect(() => {
    if (!isModalOpen) {
      setEntityForm({ title: "" });
      setAvatar(null);
      setAvatarBlob(null);
      setError("");
    }
  }, [isModalOpen]);
  return (
    <>
      <Modal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        title={entity ? "Modifier l'entité" : "Créer une entité"}
      >
        <Form onSubmit={entity ? sendEditEntity : sendCreateEntity}>
          <div className="flex items-center w-full max-w-xl gap-6  m-auto flex-col md:flex-row">
            <InputFile
              onChange={(e) => setAvatar(e.target.files[0])}
              preview={avatarBlob}
            />
            <div className="w-full md:w-1/2">
              <LineInputText
                title="Dénomination"
                className="outline-none"
                placeholder="entité / filiale"
                value={entityForm.title}
                required
                onChange={(e) =>
                  setEntityForm({ ...entityForm, title: e.target.value })
                }
              />
            </div>
          </div>
          <br />
          {error.length ? <ErrorForm message={error} /> : ""}
          <div className="flex justify-end mt-8 gap-3">
            <RoundedButton
              className="text-gray-dark border-gray-dark "
              onClick={() => setIsModalOpen(false)}
            >
              Annuler
            </RoundedButton>
            {loading ? (
              <RoundedButton isFill={true}>Chargement...</RoundedButton>
            ) : (
              <RoundedButton type="submit" isFill={true}>
                Valider
              </RoundedButton>
            )}
          </div>
        </Form>
      </Modal>
      {avatar ? (
        <CropImage
          setImage={setAvatar}
          setImageBlob={setAvatarBlob}
          src={avatar}
          ratio={null}
        />
      ) : (
        ""
      )}
    </>
  );
}
