import joinClasses from "helpers/joinClasses";

export function SubTitle({ children, className = "", ...props }) {
  return (
    <h2
      className={joinClasses(
        className,
        "uppercase tracking-widest avenir-medium-font text-xl text-blue-dark"
      )}
      {...props}
    >
      {children}
    </h2>
  );
}
