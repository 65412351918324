import { fetchDeleteEntitie } from "api/entities";
import { SubTitle } from "components/atoms";
import RoundedButton from "components/atoms/Buttons/RoundedButton";
import Toolbar from "components/molecules/Toolbar";
import { ConnectedUserTemplate } from "components/templates";

import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getEntities, entities, removeEntity } from "store/models/entities";
import { CardEntity } from "./CardEntity";
import ModalFormEntity from "./ModalFormEntity";

export default function Entities() {
  const dispatch = useDispatch();
  const entitiesInfo = useSelector(entities);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedEntity, setSelectedEntity] = useState(null);

  const sendEditEntity = async (entity) => {
    setIsModalOpen(true);
    setSelectedEntity(entity);
  };

  const sendDeleteEntity = async (entity) => {
    if (!entity.id) return;
    const response = await fetchDeleteEntitie(entity.id);
    if (response.status === "done") {
      dispatch(removeEntity(entity.id));
    }
  };

  useEffect(() => !isModalOpen && setSelectedEntity(null), [isModalOpen]);

  useEffect(() => {
    dispatch(getEntities());
  }, [dispatch]);

  return (
    <ConnectedUserTemplate>
      <Toolbar>
        <p>{entitiesInfo.data.length} entités</p>
        <RoundedButton
          isFill={true}
          onClick={() => {
            setIsModalOpen(true);
          }}
        >
          Créer une entité
        </RoundedButton>
      </Toolbar>
      <div className="w-full max-w-4xl m-auto my-10">
        <div className="border-b border-blue-dark">
          <SubTitle>ENTITÉS / FILIALES</SubTitle>
        </div>
        <ul>
          {entitiesInfo.data.map((entity, index) => (
            <CardEntity
              key={index}
              entity={entity}
              sendEditEntity={sendEditEntity}
              sendDeleteEntity={sendDeleteEntity}
            />
          ))}
        </ul>
      </div>
      <ModalFormEntity
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        entity={selectedEntity}
      />
    </ConnectedUserTemplate>
  );
}
