import { AuthTemplate } from "components/templates";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { addUserInfo, getUserInfo, userInfo } from "store/models/userInfo";
import { Link } from "react-router-dom";

import Form from "components/molecules/Form";
import { InputCode, SquareButton, SquareInputText } from "components/atoms";
import { ErrorForm } from "components/atoms";
import { fetchRegisterIp } from "api/auth";

import { useHistory } from "react-router-dom";

export default function Login() {
  const dispatch = useDispatch();
  let history = useHistory();
  const user = useSelector(userInfo);

  const [userForm, setUserForm] = useState({
    email: "",
    password: "",
  });
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [newLocation, setNewLocation] = useState(false);
  const [validationCase, setValidationCase] = useState(0);

  async function sendLogin(event) {
    event.preventDefault();
    setLoading(true);
    setError(null);
    await dispatch(getUserInfo(userForm));
  }

  async function sendValidationCode(event) {
    event.preventDefault();
    setLoading(true);
    setError(null);
    if (!validationCase.length) {
      setError("Veuillez entrer le code de validation");
      setLoading(false);
      return;
    }

    const result = await fetchRegisterIp(validationCase, user.user.email);
    if (result.status === "error") {
      setError(result.response);
      setLoading(false);
      return;
    }
    history.push("/");
    await dispatch(addUserInfo(result.response));
  }

  useEffect(() => {
    if (user.error === "A0008") {
      console.log("new location detected.");
      setNewLocation(true);
    }
  }, [user]);

  if (newLocation) {
    return (
      <AuthTemplate>
        <div>
          <h2 className="cera-black-font text-blue-dark text-3xl leading-7">
            Code de confirmation
          </h2>
          <p>Nouveau lieu de connexion détecté, vous avez reçu un mail.</p>
        </div>
        <Form
          className="w-full flex flex-col gap-3 mt-5 animate__animated animate__slideInRight"
          onSubmit={sendValidationCode}
        >
          <InputCode setCode={setValidationCase} />

          {error && <ErrorForm message={error} />}
          <br />

          {loading ? (
            <SquareButton>Chargement...</SquareButton>
          ) : (
            <SquareButton type="submit">Confirmer</SquareButton>
          )}
        </Form>
      </AuthTemplate>
    );
  }
  return (
    <AuthTemplate>
      <div>
        <h2 className="cera-black-font text-blue-dark text-3xl leading-7">
          Connexion
        </h2>
        <p>
          ou{" "}
          <Link to="/auth/register" className="underline">
            inscrivez-vous
          </Link>
        </p>
      </div>
      <Form className="w-full flex flex-col gap-3 mt-5" onSubmit={sendLogin}>
        <SquareInputText
          type="email"
          placeholder="E-mail"
          name="email"
          required={true}
          onChange={(e) => setUserForm({ ...userForm, email: e.target.value })}
        />
        <SquareInputText
          type="password"
          placeholder="Mot de passe"
          name="password"
          required={true}
          onChange={(e) =>
            setUserForm({ ...userForm, password: e.target.value })
          }
        />
        {error || user.error ? <ErrorForm message={error || user.error} /> : ""}
        <br />

        {loading ? (
          <SquareButton>Chargement...</SquareButton>
        ) : (
          <SquareButton type="submit">Connexion</SquareButton>
        )}
      </Form>
    </AuthTemplate>
  );
}
