import { useSelector, useDispatch } from "react-redux";
import { userInfo, removeUserInfo } from "../../store/models/userInfo";
import { ConnectedUserTemplate } from "../../components/templates";
import RoundedButton from "components/atoms/Buttons/RoundedButton";

export default function Home() {
  const user = useSelector(userInfo);
  const dispatch = useDispatch();

  return (
    <ConnectedUserTemplate>
      <ul>
        <li>id: {user.user.id}</li>
        <li>email: {user.user.email}</li>
        <li>firstname: {user.user.firstname}</li>
        <li>lastname: {user.user.lastname}</li>
        <li>role: {user.user.role}</li>
        <li>status: {user.user.status}</li>
        <br />
        <li>
          <RoundedButton
            isFill={true}
            onClick={() => dispatch(removeUserInfo())}
          >
            Déconnexion
          </RoundedButton>
        </li>
      </ul>
    </ConnectedUserTemplate>
  );
}
