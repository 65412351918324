import joinClasses from "helpers/joinClasses";
import { useState } from "react";
import { Input } from "./Inputs";

export function SquareInputText({ className, type, ...props }) {
  const [isVisibled, setIsVisibled] = useState(false);

  if (type === "text" || type === "email" || type === "email") {
    return (
      <Input
        type={type}
        {...props}
        className={joinClasses(
          className,
          "placeholder-blue-dark text-blue-dark border border-blue-dark px-2 py-3 w-full"
        )}
      />
    );
  }
  if (type === "password") {
    return (
      <div className="relative">
        <Input
          type={type}
          {...props}
          className={joinClasses(
            className,
            "placeholder-blue-dark text-blue-dark border border-blue-dark px-2 py-3 w-full"
          )}
        />
        <i
          className={`absolute cursor-pointer ${
            isVisibled ? "icon-eye-regular" : "icon-eye-slash-regular"
          } right-1 px-3 top-4 `}
          onClick={() => setIsVisibled(!isVisibled)}
        />
      </div>
    );
  }
  return <></>;
}
