export default function Loader() {
  return (
    <div className="h-screen w-screen absolute top-0 left-0 flex justify-center items-center bg-gray-lightest animate__animated animate__fadeIn">
      <div className="transform scale-50 ">
        <div className="lds">
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  );
}
