import joinClasses from '../../helpers/joinClasses'

function Form({
  children,
  className = '',
  ...props
}) {
  return (
    <form
      className={joinClasses(className, '')}
      {...props}
    >
      {children}
    </form>
  )
}

export default Form

// How you can compose your Form

// [setUsername, username] = useState('')
// [setPassword, password] = useState('')
// [setFirstName, firstName] = useState('')
// [setLastName, lastName] = useState('')
//
//
//     // Pages/Register.jsx
// <Form onSubmit={() => { register(usernameState) }}>
//   <Input onChange={setUsername} text={username} />
//   <Input onChange={setPassword} />
//   <Input onChange={setFirstName} />
//   <Input onChange={setLastName} />
// </Form>
//
//   // Pages/Login.jsx
// [setUsername, username] = useState('')
// [setPassword, password] = useState('')
//
// <Form onSubmit={() => { dispatch(login(username, password)) }}>
//   <Input onChange={setUsername} text={username} />
//   <Input onChange={setPassword} />
// </Form>
